import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import PageContainer from '../../components/common/page-container'
import Link from '../../components/common/link'

export default (props) => (
  <Layout title="Serverless">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Serverless</Title>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      packagedby can provide a range of serverless services. These include:
      
      <ul className="mt-2">
        <li>API Development using Functions as a Service</li>
        <li><Link href="/services/serverless/voice">Voice Applications</Link></li>
        <li><Link href="/services/serverless/gatsby-js">Gatsby.js Websites</Link></li>
      </ul>
    </PageContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "voice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`